// https://www.placemark.io/
import { Button } from "antd";
import * as React from "react";
import HomeLayout from "../blocks/layout/home";
import Seo from "../blocks/layout/seo";
import { HiOutlineUsers } from "react-icons/hi";
import { FaRobot, FaCloud } from "react-icons/fa";
import { TfiBoltAlt } from "react-icons/tfi";
import { MdOutlineCodeOff, MdOutlineCloudUpload } from "react-icons/md";
import { VscCheckAll } from "react-icons/vsc";
import VideoPlayerBox from "../blocks/videpPlayerBox";
import VideoPlayerLoop from "../blocks/videoPlayerLoop";
import { Link } from "gatsby";
import {PartnersCarousel} from "../components/PartnersCarousel";

const IndexPage = () => {
  return (
    <>
      <Seo title="Output: Content Syndication, Automated" />
      <HomeLayout authPage={false}>
        <main>
          <div className="hero-section">
            <h2
            className="title"
            >
              Build and manage content feeds instantly from any structured data
              source. No coding needed.
            </h2>
            <p>
              Turn media assets into a cloud based API product within minutes.
              Automate publishing to the API from your data source to reduce
              manual tasks and improve efficiency.
            </p>
            <Link to="/signup">
              <Button
                type="primary"
                className="hero-cta-button"
              >
                Start your API
              </Button>
            </Link>
          </div>
          <PartnersCarousel />
          <div className="section-two">
            <div className="features-list">
              <div className="feature-card">
                <div className="head">
                  <MdOutlineCodeOff size={30} />
                  <div className="feature-name">No Code</div>
                </div>
                <p>No coding required to build your partner APIs.</p>
              </div>
              <div className="feature-card">
                <div className="head">
                  <FaRobot size={30} />
                  <div className="feature-name">Automation</div>
                </div>
                <p>
                  Automated syncing of your channels content, no manual upload
                  and entering title, description, metadata into a CMS
                </p>
              </div>
              <div className="feature-card">
                <div className="head">
                  <VscCheckAll size={30} />
                  <div className="feature-name">Formats</div>
                </div>
                <p>
                  Industry standardized MRSS formats in JSON, XML, no
                  engineering or customization needed
                </p>
              </div>
              <div className="feature-card">
                <div className="head">
                  <HiOutlineUsers size={30} />
                  <div className="feature-name">Multi-Channel</div>
                </div>
                <p>
                  Set up or access multiple channels in a centralized simple
                  platform
                </p>
              </div>
              <div className="feature-card">
                <div className="head">
                  <MdOutlineCloudUpload size={30} />
                  <div className="feature-name">Cloud Hosted</div>
                </div>
                <p>
                  Centralized and accessible by any remote team member, no more
                  navigating hard drives or uploading/downloading files to
                  google drives
                </p>
              </div>
              <div className="feature-card">
                <div className="head">
                  <TfiBoltAlt size={30} />
                  <div className="feature-name">Performance</div>
                </div>
                <p>
                  Speed of our cloud server makes viewing and syndicating large
                  video files instant
                </p>
              </div>
            </div>
          </div>

          <div className="section-three">
            <div className="feature-section">
              <div className="landing-feature-block">
                <span style={{ fontSize: 32, fontWeight: 400 }}>
                  1. Add your YouTube channels
                </span>
                <span
                  style={{
                    fontSize: 16,
                  }}
                >
                  Get your channel IDs from your YouTube dashoard and add them
                  here.
                </span>
              </div>
              <div
                className="video-player-box"
              >
                <VideoPlayerBox
                  url="https://www.youtube.com/watch?v=ASXJLo-sEHk"
                  showControls
                />
              </div>
            </div>

            <div className="feature-section">
              <div className="landing-feature-block">
                <span style={{ fontSize: 32, fontWeight: 400 }}>
                  2. Import videos
                </span>
                <span
                  style={{
                    fontSize: 16,
                  }}
                >
                  Select the channel and the videos you want to import to the
                  cloud for distribution.
                </span>
              </div>
              <VideoPlayerLoop url="https://output.sfo3.cdn.digitaloceanspaces.com/website/add-channel.mov" />
            </div>

            <div className="feature-section">
              <div className="landing-feature-block">
                <span style={{ fontSize: 32, fontWeight: 400 }}>
                  3. Distribute
                </span>
                <span
                  style={{
                    fontSize: 16,
                  }}
                >
                  Create industry standard MRSS feeds to syndicate everywhere,
                  with one click.
                </span>
              </div>
              <VideoPlayerLoop url="https://output.sfo3.cdn.digitaloceanspaces.com/website/import-videos.mov" />
            </div>

            <div className="feature-section" g>
              <div className="landing-feature-block">
                <span style={{ fontSize: 32, fontWeight: 400 }}>4. Done.</span>
                <span
                  style={{
                    fontSize: 16,
                  }}
                >
                  Login to start.
                </span>
              </div>

              <VideoPlayerLoop url="https://output.sfo3.cdn.digitaloceanspaces.com/website/Output_Distributor_Demo.mp4" />
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link to="/signup">
                <Button className="cta-button" type="primary" size="large">
                  Try for free
                </Button>
              </Link>
            </div>
          </div>
        </main>
      </HomeLayout>
    </>
  );
};

export default IndexPage;
