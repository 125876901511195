import AmazonAlexaSvg from '../../images/companyLogos/amazon-alexa.svg';
import SpotifySvg from '../../images/companyLogos/spotify.svg';
import AmaxonFreeveeSvg from '../../images/companyLogos/amazon-freevee.svg';
import YoutubeSvg from '../../images/companyLogos/youtube.svg';
import AmazonPrimeSvg from '../../images/companyLogos/amazon-prime.svg';
import TiktokSvg from '../../images/companyLogos/tiktok.svg';
import GoProSvg from '../../images/companyLogos/goPro.svg';
import ParamountSvg from '../../images/companyLogos/paramount.svg';
import AtmosphereTvSvg from '../../images/companyLogos/atmosphere-TV.svg';
import TaboolaSvg from '../../images/companyLogos/taboola.svg';
import PlutoTvSvg from '../../images/companyLogos/pluto-TV.svg';
import PrimisSvg from '../../images/companyLogos/primis.svg';
import RumbleSvg from '../../images/companyLogos/rumble.svg';
import BallIsLifeSvg from '../../images/companyLogos/ball-is-life.svg';
import BrutAmericaSvg from '../../images/companyLogos/brut-america.svg';
import RokuSvg from '../../images/companyLogos/roku.svg';
import ComsatSvg from '../../images/companyLogos/comsat.svg';
import MsnSvg from '../../images/companyLogos/msn.svg';
import BetterCollectiveSvg from '../../images/companyLogos/better-collective.svg';
import JungleCreationsSvg from '../../images/companyLogos/jungle-creations.svg';
import JustForLaughsSvg from '../../images/companyLogos/just-for-laughs.svg';
import SlingSvg from '../../images/companyLogos/sling.svg';
import TwistedFoodSvg from '../../images/companyLogos/twisted-food.svg';
import VideoElephantSvg from '../../images/companyLogos/video-elephant.svg';
import XumoSvg from '../../images/companyLogos/xumo.svg';
import DishSvg from '../../images/companyLogos/dish.svg';

export const PARTNERS_DATA = [
    {img: AmazonAlexaSvg, alt: 'Amazon Alexa'},
    {img: SpotifySvg, alt: 'Spotify'},
    {img: AmaxonFreeveeSvg, alt: 'Amazon Freevee'},
    {img: YoutubeSvg, alt: 'Youtube'},
    {img: AmazonPrimeSvg, alt: 'Amazon Prime'},
    {img: TiktokSvg, alt: 'Tiktok'},
    {img: GoProSvg, alt: 'GoPro'},
    {img: ParamountSvg, alt: 'Paramount'},
    {img: AtmosphereTvSvg, alt: 'Atmosphere TV'},
    {img: TaboolaSvg, alt: 'Taboola'},
    {img: PlutoTvSvg, alt: 'Pluto TV'},
    {img: PrimisSvg, alt: 'Primis'},
    {img: RumbleSvg, alt: 'Rumble'},
    {img: BallIsLifeSvg, alt: 'Ball Is Life'},
    {img: BrutAmericaSvg, alt: 'Brut America'},
    {img: RokuSvg, alt: 'Roku'},
    {img: ComsatSvg, alt: 'Comsat'},
    {img: MsnSvg, alt: 'Msn'},
    {img: BetterCollectiveSvg, alt: 'Better Collective'},
    {img: JungleCreationsSvg, alt: 'Jungle Creations'},
    {img: JustForLaughsSvg, alt: 'Just For Laughs'},
    {img: SlingSvg, alt: 'Sling'},
    {img: TwistedFoodSvg, alt: 'Twisted Food'},
    {img: VideoElephantSvg, alt: 'Video Elephant'},
    {img: XumoSvg, alt: 'Xumo'},
    {img: DishSvg, alt: 'Dish'},
];