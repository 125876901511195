import React, { useRef, useEffect } from "react";
import './styles.scss';
import {PARTNERS_DATA} from "./constants";

export const PartnersCarousel = () => {
    const carouselRef = useRef(null);

    const scroll = (carousel) => {
        if (carousel.scrollLeft >= (carousel.scrollWidth - carousel.clientWidth)) {
            carousel.scrollLeft = 0;
        } else {
            carousel.scrollLeft += 0.5;
        }
    };

    useEffect(() => {
        const interval = setInterval(() => scroll(carouselRef.current), 40);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <section className="partners-carousel">
            <div className="partners-carousel_row" ref={carouselRef}>
                {PARTNERS_DATA.map(({ img, alt }, index) => (
                    <img key={index} src={img} alt={alt} className="partners-carousel_img" />
                ))}
            </div>
        </section>
    );
};